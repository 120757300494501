<template>
  <div class="vuse-content-wrapper positions">
    <v-container fluid>
      <smart-breadcrumbs :items="navigationElements" />
      <!-- Content -->
      <v-card>
        <v-toolbar color="primary" dark flat dense>
          <template v-slot:default>
            <v-tabs v-model="model" centered slider-color="teal">
              <v-tab href="#1">Empresas</v-tab>
              <v-tab href="#2"> Formatos de Empresas </v-tab>

              <!-- <v-tab href="#tab-3"> Resultados de gestión </v-tab> -->
            </v-tabs>
          </template>
        </v-toolbar>

        <keep-alive>
          <v-tabs-items v-model="model" :key="model">
            <v-tab-item value="1">
              <companies-list />
            </v-tab-item>
            <v-tab-item value="2">
              <types />
            </v-tab-item>
          </v-tabs-items>
        </keep-alive>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import CompaniesList from '@/components/CorporateStructure/CompaniesList'
  import Types from '@/components/CorporateStructure/Types'

  export default {
    name: 'positions',
    components: {
      CompaniesList,
      Types,
    },
    data() {
      return {
        model: this.$route.query.tap ? parseInt(this.$route.query.tap) : '1',
        navigationElements: [
          {
            text: 'Estructura Corporativa',
            disabled: false,
            query: null,
            params: null,
            name: 'business/corporate-structure/AdminPanel',
            href: '/business/corporate-structure/admin-panel',
          },
          {
            text: 'Empresas',
            disabled: true,
          },
        ],
      }
    },
    created() {
      this.handleTab()
    },
    methods: {
      handleTab() {
        const route = window.location.href.split('tab=')
        if (route.length > 1) {
          this.model = route[1]
        }
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .positions {
    .v-breadcrumbs {
      padding: 5px 10px !important;
    }
    .v-tabs-slider {
      margin-top: -5px;
    }
  }
</style>
