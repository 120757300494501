<template>
  <div class="vuse-content-wrapper">
    <!-- Content -->
    <v-card flat>
      <div class="filters py-3">
        <h2 class="text-center">Formatos de Empresas</h2>
        <v-row justify="end">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                dark
                class="mb-2"
                style="float: right"
                @click="handleCreate()"
                >Crear
              </v-btn>
            </template>

            <span>Crear formato</span>
          </v-tooltip></v-row
        >
        <label>Filtrar por:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
            <v-btn
              color="primary"
              style="min-width: auto; position: absolute; right: 50px"
              dark
              class="px-1 py-1"
              v-if="filterActivated"
              @click.prevent="
                filterActivated = false
                cleanFilters()
                getTypes()
              "
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  style="min-width: auto; position: absolute; right: 10px; height: 25px"
                  dark
                  class="px-1 py-1"
                  @click.prevent="
                    filterActivated = true
                    getTypes()
                  "
                  ><v-icon dark> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>Filtrar</span>
            </v-tooltip>
          </v-row>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </v-card>
    <modal
      name="typeModal"
      :height="'80%'"
      :width="widthDialog"
      :max-width="500"
      :adaptive="true"
      :key="crudIndex"
    >
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4">
          {{ flow === 'create' ? 'Crear formato' : 'Editar formato' }}
        </h2>

        <span v-if="item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <div class="row mx-0 mt-4">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Nombre"
                v-model="name"
                type="text"
              />
            </v-col>
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <!-- <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn> -->
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('typeModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'Types',
    components: {
      SimpleList,
    },
    data() {
      return {
        config: {},
        loading: true,
        name: '',
        flow: '',
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Nombre', value: 'name' },
          { text: 'Acciones', value: 'actions', align: 'left', width: '140px' },
        ],
        valid: true,
        items: [],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        createdAt: '',
        crudIndex: 1,
        filterActivated: false,
        loadingText: 'Cargando registros',
        filters: {
          search: '',
        },
        toDay: new Date(),
        orderBy: { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 6 || '6 caracteres como mínimo',
          max: (v) => v.length <= 200 || 'Debe poser menos de 200 caracteres',
        },
      }
    },
    created() {
      this.getTypes()
    },
    computed: {
      widthDialog: {
        get() {
          return this.$vuetify.breakpoint.smAndDown ? '95%' : '60%'
        },
        set(newValue) {
          this.crudIndex++
        },
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      cleanFilters() {
        this.filters = {
          search: '',
        }
      },
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getTypes()
      },

      async getTypes() {
        this.loading = true
        let queryParams = ''
        let orderString = ''

        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }

        if (this.filterActivated) {
          let searchString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString
        }
        this.$axios
          .get('types?page=' + this.page + queryParams + orderString)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              x.showDelete = false
              if (x.protected !== true) {
                x.showDelete = true
              }
              return x
            })
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.name = ''
        this.$modal.show('typeModal')
      },
      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = item.name
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )
        this.$modal.show('typeModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.$axios.post('types', { name: this.name, code: 'franchisee' }).then(() => {
          this.getTypes()
          this.$modal.hide('typeModal')
        })
      },
      async saveItem() {
        this.$axios.patch('types/' + this.item.id, { name: this.name }).then(() => {
          this.page = 1
          this.getTypes()
          this.$modal.hide('typeModal')
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getTypes()
      },
      async deleteItem(item) {
        this.$axios.delete('types/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getTypes()
        })
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';

  // .v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  //   margin: -8px 36px;
  // }
  // .flip-list-move {
  //   transition: transform 0.5s;
  // }
  // .no-move {
  //   transition: transform 0s;
  // }
  // .ghost {
  //   opacity: 0.5;
  //   background: #c8ebfb;
  // }
  // .list-group {
  //   min-height: 20px;
  // }
  // .list-group-item {
  //   cursor: move;
  //   list-style: none;
  // }
  // .list-group-item i {
  //   cursor: pointer;
  // }
  // .multiselect__content-wrapper {
  //   height: 100px;
  // }
</style>
