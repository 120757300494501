<template>
  <div class="vuse-content-wrapper">
    <!-- Content -->
    <v-card flat>
      <div class="filters">
        <v-row no-gutters class="crud__title-row">
          <help class="help--crud" :helpId="9" />
          <h2 class="text-center">
            Empresas

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  v-bind="attrs"
                  v-on="on"
                  dark
                  absolute
                  right
                  style="float: right"
                  color="primary"
                  @click.prevent="handleCreate()"
                  >Crear
                </v-btn>
              </template>
              <span>Crear Empresa</span>
            </v-tooltip>
          </h2>
        </v-row>

        <label>Filtrar por:</label>

        <v-form ref="formCompany" v-model="valid">
          <v-row class="mx-0">
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <multiselect
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                placeholder="País"
                :custom-label="countryName"
                v-model="filters.country"
                :options="countries"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
              <multiselect
                track-by="id"
                label="name"
                placeholder="Empresa Principal"
                v-model="filters.company"
                :options="companiesSearched"
                :multiple="false"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
                :options-limit="300"
                :limit="3"
                :limit-text="limitText"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="getCompaniesSelector"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <multiselect
                track-by="id"
                label="name"
                placeholder="Nivel"
                v-model="filters.level"
                :options="levels"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <multiselect
                track-by="id"
                label="name"
                placeholder="Tipo"
                v-model="filters.type"
                :options="types"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <multiselect
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                placeholder="Activa"
                v-model="filters.active"
                :options="activeOptions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              />
            </v-col>
            <v-col cols="12" lg="1" class="d-flex justify-end justify-lg-start">
              <v-btn
                color="primary"
                style="min-width: auto; height: 25px"
                dark
                class="pa-1 mx-1"
                v-if="filterActivated"
                @click.prevent="
                  filterActivated = false
                  cleanFilters()
                  getCompanies()
                "
              >
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    style="min-width: auto; height: 25px"
                    dark
                    class="pa-1 mx-1"
                    @click.prevent="
                      filterActivated = true
                      getCompanies()
                    "
                    ><v-icon dark> mdi-filter </v-icon>
                  </v-btn>
                </template>
                <span>Filtrar</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :page="page"
        :pages="pages"
        :extraButtoms="extraButtoms"
        :showCreate="false"
        :showDelete="true"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
        @showUsers="handleUsersRedirect($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Empresa</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Empresa</h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <div class="row mx-0 mt-4">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Nombre"
                v-model="name"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%">Nivel</label>
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  label="name"
                  placeholder="Seleccionar nivel"
                  v-model="level"
                  :options="levels"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%"
                  >Formato</label
                >
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  label="name"
                  placeholder="Seleccionar"
                  deselect-label="Remover"
          select-label="Seleccionar"
          selected-label="Seleccionado"
                  v-model="selectedType"
                  :options="types"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 40%"
                  >Empresa principal
                </label>
                <multiselect
                  style="width: 60%"
                  v-model="selectedCompany"
                  label="name"
                  track-by="id"
                  placeholder="Buscar"
                  open-direction="bottom"
                  :options="companiesSearched"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="true"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :limit-text="limitText"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="getCompaniesSelector"
                >
                  <template slot="tag" slot-scope="{ option, remove }"
                    ><span class="custom__tag"
                      ><span>{{ option.name }}</span
                      ><span class="custom__remove" @click="remove(option)"
                        >❌</span
                      ></span
                    ></template
                  >
                  <!-- <template slot="clear" slot-scope="">
                    <div
                      class="multiselect__clear"
                      v-if="selectedCompany && selectedCompany.length"
                    />
                    <span slot="noResult"
                      >No se encontraron elementos. Considere cambiar la consulta de búsqueda.</span
                    >
                  </template> -->
                </multiselect>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%">País</label>
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  placeholder="Seleccionar"
                  :custom-label="countryName"
                  v-model="country"
                  :options="countries"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%"
                  >Módulos</label
                >
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  label="name"
                  placeholder="Seleccionar disponibles"
                  v-model="selectedModules"
                  :options="modules"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Descripción"
                v-model="description"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-text-field
                counter
                :rules="[rules.required, rules.email, rules.max]"
                label="Email"
                v-model="email"
                type="email"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <!--<label style="font-size: 0.8rem; margin-bottom: 5px">Teléfono</label>-->
              <v-text-field
                counter
                v-mask="phoneMask"
                :rules="[rules.required, rules.maxNumber]"
                label="Teléfono"
                v-model="phone"
                type="text"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-text-field
                counter
                label="Sitio web"
                v-model="website"
                :rules="[rules.url]"
                persistent-hint
                :hint="'Ejemplo: https://google.com'"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-text-field counter label="Latitude" v-model="latitude" type="number" />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-text-field counter label="Longitude" v-model="longitude" type="number" />
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
              sm="12"
              style="padding-top: 0px; padding-bottom: 12px"
            >
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Dirección"
                v-model="address"
              />
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12">
              <v-checkbox :label="active ? 'Activa' : 'Inactiva'" v-model="active" />
            </v-col>
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'Companies',
    components: {
      SimpleList,
    },
    data() {
      return {
        config: {},
        companies: [],
        loading: true,
        isLoading: false,
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          {
            text: 'Empresa Principal',
            value: 'father.name',
          },
          { text: 'Nombre', value: 'name' },
          { text: 'Nivel', value: 'level.name' },
          { text: 'Tipo', value: 'type.name' },
          { text: 'Activa', value: 'activeText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '140px' },
        ],
        items: [],
        types: [],
        levels: [],
        modules: [],
        selectedType: [],
        selectedModules: [],
        companiesSearched: [{ id: 0, name: 'No posee empresa principal' }],
        page: 1,
        pages: 1,
        item: {},
        selectedCompany: null,
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
          active: null,
          country: null,
          type: null,
          level: null,
          company: null,
        },
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activas' },
          { id: 0, name: 'Inactivas' },
        ],
        phoneMask: [
          '+',
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
        ],
        active: false,
        type: 0,
        level: '',
        address: '',
        email: '',
        latitude: '',
        longitude: '',
        link: '',
        phone: '',
        name: '',
        description: '',
        website: '',
        cities: [],
        countries: [{ id: null, name: 'Todos' }],
        // users: [ { id: null, name: 'Todos' } ],
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        country: '',
        selectCountryData: [],
        selectCityData: [],
        loadingText: 'Cargando registros',
        datas: [],
        arrayOfData: [],
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poser menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
        extraButtoms: [
          {
            text: 'Usuarios',
            icon: 'mdi-account-group',
            event: { active: true, name: 'showUsers' },
          },
        ],
      }
    },
    watch: {},
    created() {
      this.getCompanies()
      this.getCountries()
      this.getTypes()
      this.getLevels()
      this.getModules()
      this.getCompaniesSelector()
    },
    computed: {},
    methods: {
      toLocaleDateString: toLocaleDateString,

      handleUsersRedirect(company) {
        this.$router.push({
          name: 'business/corporate-structure/Users',
          params: { companyId: company.id },
        })
      },
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getCompanies()
      },
      limitText(count) {
        return `and ${count} other companies`
      },
      async getCompaniesSelector(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get(
            'companies?with[]=module&with[]=type&with[]=countries&order=name&by=asc' +
              searchStr
          )
          .then((response) => {
            this.companiesSearched = response.data.data
            this.isLoading = false
          })
      },
      getCompany(companyId) {
        this.companiesSearched = [{ id: 0, name: 'No posee empresa principal' }]
        if (companyId !== 0) {
          this.$axios.get('companies/' + companyId).then((response) => {
            this.selectedCompany = response.data
          })
        } else {
          this.selectedCompany = { id: 0, name: 'No posee empresa principal' }
        }
      },
      clearAll() {
        this.selectedCompany = []
      },
      isURL(str) {
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },

      fullName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },

      countryName({ name, iso2 }) {
        if (name && iso2) {
          return `${name} (${iso2})`
        } else if (name) {
          return name
        }
      },

      cleanFilters() {
        this.filters = {
          search: '',
          active: null,
          country: null,
        }
      },

      async getCompanies() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          let countryString = ''
          let companyString = ''
          let levelString = ''
          let typeString = ''
          let activeString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.country && this.filters.country.id) {
            countryString = '&country_id=' + this.filters.country.id
          }
          if (this.filters.company && this.filters.company.id) {
            companyString = '&company_id=' + this.filters.company.id
          }
          if (this.filters.level && this.filters.level.id) {
            levelString = '&level_id=' + this.filters.level.id
          }
          if (this.filters.type && this.filters.type.id) {
            typeString = '&type_id=' + this.filters.type.id
          }
          if (this.filters.active && this.filters.active.id === 1) {
            activeString = '&active=1'
          } else if (this.filters.active && this.filters.active.id === 0) {
            activeString = '&active=0'
          }
          queryParams =
            searchString +
            countryString +
            companyString +
            levelString +
            typeString +
            activeString
        }
        this.$axios
          .get(
            'companies?with[]=countries&with[]=father&with[]=level&with[]=type&with[]=module&page=' +
              this.page +
              queryParams +
              orderString
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((x) => {
              x.active ? (x.activeText = 'Si') : (x.activeText = 'No')
            })
            this.loading = false
          })
      },

      async getTypes() {
        this.$axios.get('types?limit=20').then((response) => {
          this.types = this.types.concat(response.data)
        })
      },

      async getLevels() {
        this.$axios.get('levels?limit=5').then((response) => {
          this.levels = this.levels.concat(response.data)
        })
      },

      async getModules() {
        this.$axios.get('modules?limit=50').then((response) => {
          this.modules = this.modules.concat(response.data)
        })
      },

      async getCountries() {
        this.$axios.get('countries?limit=300').then((response) => {
          this.countries = this.countries.concat(response.data)
        })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.description = ''
        this.email = ''
        this.phone = ''
        this.latitude = ''
        this.longitude = ''
        this.website = ''
        this.address = ''
        this.active = false
        this.selectedType = []
        this.selectedModules = []
        this.selectedCompany = ''
        this.level = ''
        this.country = ''

        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.description = this.item.description
        this.email = this.item.email
        this.phone = this.item.phone
        this.latitude = this.item.latitude
        this.longitude = this.item.longitude
        this.website = this.item.link
        this.address = this.item.address
        this.country = this.item.countries[0]
        this.active = this.item.active
        if (this.item.company_id && this.item.company_id === 0) {
          this.selectedCompany = { id: 0, name: 'No posee empresa principal' }
        } else {
          this.getCompany(this.item.company_id)
        }
        this.selectedType = this.item.type
        this.selectedModules = this.item.module ? this.item.module : []
        this.level = this.item.level
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        const formData = new FormData()
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        formData.append('name', this.name)
        if (this.description && this.description !== '') {
          formData.append('description', this.description)
        }
        if (this.website && this.website !== '') {
          formData.append('link', this.website.toLowerCase())
        }
        if (this.selectedCompany && this.selectedCompany !== '') {
          formData.append('company_id', parseInt(this.selectedCompany.id))
        }
        if (this.level && this.level !== '') {
          formData.append('level_id', this.level.id)
        }
        if (this.latitude && this.latitude !== '') {
          formData.append('latitude', this.latitude)
        }
        if (this.longitude && this.longitude !== '') {
          formData.append('longitude', this.longitude)
        }
        if (this.address && this.address !== '') {
          formData.append('address', this.address)
        }
        if (this.phone && this.phone !== '') {
          formData.append('phone', this.phone)
        }
        if (this.email && this.email !== '') {
          formData.append('email', this.email)
        }
        formData.append('active', this.active)
        formData.append('type_id', this.selectedType.id)
        this.selectedModules.forEach((module) => {
          formData.append('modules[]', module.id)
        })
        if (this.country && this.country.id && this.country.id !== undefined) {
          formData.append('countries[]', this.country.id)
        }

        this.$axios.post('companies', formData, this.config).then(() => {
          this.page = 1
          this.getCompanies()
          this.$modal.hide('crudModal')
          this.loading = false
        })
      },

      async saveItem() {
        const formData = new FormData()
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        formData.append('_method', 'patch')
        formData.append('name', this.name)
        if (this.website && this.website !== '') {
          formData.append('description', this.description)
        }
        if (this.website && this.website !== '') {
          formData.append('link', this.website.toLowerCase())
        }
        if (this.selectedCompany && this.selectedCompany !== '') {
          formData.append('company_id', parseInt(this.selectedCompany.id))
        }
        if (this.level && this.level !== '') {
          formData.append('level_id', this.level.id)
        }
        if (this.latitude && this.latitude !== '') {
          formData.append('latitude', this.latitude)
        }
        if (this.longitude && this.longitude !== '') {
          formData.append('longitude', this.longitude)
        }
        if (this.address && this.address !== '') {
          formData.append('address', this.address)
        }
        if (this.phone && this.phone !== '') {
          formData.append('phone', this.phone)
        }
        if (this.email && this.email !== '') {
          formData.append('email', this.email)
        }
        formData.append('active', this.active)
        formData.append('type_id', this.selectedType.id)
        this.selectedModules.forEach((module) => {
          formData.append('modules[]', module.id)
        })
        if (this.country && this.country.id && this.country.id !== undefined) {
          formData.append('countries[]', this.country.id)
        }

        this.$axios.post('companies/' + this.item.id, formData, this.config).then(() => {
          this.page = 1
          this.getCompanies()
          this.$modal.hide('crudModal')
          this.loading = false
        })
      },

      async deleteItem(item) {
        this.$axios.delete('companies/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getCompanies()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getCompanies()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
